import { notification } from "antd";
import { ChartRepoItem, DashboardState } from "types";

export const ROUTES = {
  welcome: "/welcome",
  shake_hands: "/shake-hands",
  login: "/login",
  verify: "/verify",
  dashboard: "/dashboard",
  vehicles: "/vehicles",
  
  vehicles_map: "/vehicles/map",
  vehicles_list: "/vehicles/table",
  vehicle_detail: "/vehicles/detail",
  clients: "/organizations",
  notifications: "/notifications",
  users: "/users",
  user_detail: "/users/detail",
  users_table: "/users/table",
  users_table_all: "/users/table/all",
  users_table_admins: "/users/table/admins",
  users_table_client_admins: "/users/table/client admins",
  users_table_drivers: "/users/table/drivers",
  users_table_mechanics: "/users/table/mechanics",
  users_list: "/users/list",
  users_list_all: "/users/list/all",
  users_list_admins: "/users/list/admins",
  users_list_drivers: "/users/list/drivers",
  users_list_mechanics: "/users/list/mechanics",
  zones: "/zones",
  kiosks: "/kiosk",
  zones_list: "/zones/list",
  zones_map: "/zones/map",
  issues: "/issues",
  issue_detail: "/issues/detail",
  issues_list: "/issues/list",
  issues_table: "/issues/table",
  financials: "/financials",
  next_step: "/next-step",
  settings: "/settings",
  vehicle_model: "/vehicle_model",
  vehicle_models_list: "/vehicle_model/table",
  vehicle_model_detail: "/vehicle_model/detail",
  battery: "/battery",
  batteries_list: "/battery/table",
  battery_detail: "/battery/detail",
  battery_model: "/battery_model",
  battery_model_list: "/battery_model/table",
  add_zone: "/add-zone",
  edit_zone: "/edit-zone",
  battery_heartbeat: "/battery-heartbeats"

};

export const MAP_PROPS = {
  googleMapURL:
    "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",
  position: { lat: -3.397, lng: 15.644 },
  defaultZoom: 10,
};

export const ISSUES_CHARTS_META = [
  {
    name: "Issue Tiles",
    className: "--issue-tiles",
    widget: "issue-tiles",
    isWrapped: false,
  },
  {
    name: "Issy by Status",
    className: "",
    widget: "issue-by-status",
    isWrapped: true,
  },
  {
    name: "Issue Count",
    className: "--issue-count",
    widget: "issue-count",
    isWrapped: true,
  },
  {
    name: "Most Recent Unresolved Issues",
    className: "",
    widget: "unresolved-issues",
    isWrapped: true,
  },
];

export const VEHICLES_CHARTS_META = [
  {
    name: "Vehicle Tiles",
    className: "--vehicle-tiles",
    widget: "vehicle-tiles",
    isWrapped: false,
  },
  {
    name: "Un-Allocated Vehicles",
    className: "--un-allocated-vehicles",
    widget: "unassigned-vehicles",
    isWrapped: true,
  },
  {
    name: "Tasks Distribution",
    className: "",
    widget: "tasks-distribution",
    isWrapped: true,
  },
  {
    name: "Tasks By Status",
    className: "",
    widget: "task-by-status",
    isWrapped: true,
  },
  {
    name: "Alert Stats",
    className: "",
    widget: "alert-stats",
    isWrapped: true,
  },
];

export const FINANCIALS_CHARTS_META = [
  {
    name: "Financials Tiles",
    className: "--financials-tiles",
    widget: "financials-tiles",
    isWrapped: false,
  },
  // {
  //   name: "Traffic Fines Count",
  //   className: "",
  //   widget: "traffic-fines-count",
  //   isWrapped: true,
  // },
  //  {
  //   name: "Excess Insurance Count",
  //   className: "",
  //   widget: "excess-insurance-count",
  //   isWrapped: true,
  // },
  {
    name: "Additional Charges Count",
    className: "",
    widget: "additional-costs",
    isWrapped: true,
  },
  // {
  //   name: "Repair Invoices Count",
  //   className: "",
  //   widget: "repair-invoices-count",
  //   isWrapped: true,
  // },
//  {
//     name: "Traffic Salik Count",
//     className: "",
//     widget: "traffic-salik-count",
//     isWrapped: true,
//   },
];

export const COLUMN_CHART_META = [
  { name: "Issue by Status", span: 1, widget: "column-chart" },
];

export const COUNTRIES_LIST = {
  PK: { primary: "Pakistan", secondary: "+92" },
  QA: { primary: "Qatar", secondary: "+974" },
  AE: { primary: "United Arab Emirates", secondary: "+971" },
  SA: { primary: "Saudi Arabia", secondary: "+966" },
  KW: { primary: "Kuwait", secondary: "+965" },
};

// export const END_POINTS = {
//   vehicles: "http://192.168.1.104:5001",
//   issues: "http://192.168.1.104:5001",
//   auth: "http://192.168.1.104:5000",
//   user: "http://192.168.1.104:5000",
//   billings: "http://192.168.1.104:5002",
// };
export const END_POINTS = {
  vehicles: "https://fms.zyptechnologies.com",
  issues: "https://fms.zyptechnologies.com",
  auth: "https://fms.zyptechnologies.com",
  user: "https://fms.zyptechnologies.com",
  billings: "https://fms.zyptechnologies.com",
};

// export const END_POINTS = {
//   vehicles: "https://zyp.stg.fluxnet.co",
//   issues: "https://zyp.stg.fluxnet.co",
//   auth: "https://zyp.stg.fluxnet.co",
//   user: "https://zyp.stg.fluxnet.co",
//   billings: "https://zyp.stg.fluxnet.co",
// };

export const CLIENT_DOMAIN_ID_DEV = "2d0978ac-aea6-44d4-812e-cdaf26d79862";

export const CLIENT_DOMAIN_ID_PROD = "2d0978ac-aea6-44d4-812e-cdaf26d79862";

export const CLIENT_CONSIGNEE_ID = "68a58b1c-8872-426d-b39f-73c2368656f9";

export const PAGE_SIZE = 20;

export const DATE_FORMAT = "MMM dd, yyyy";

export const TIME_FORMAT = "hh:mm aa";

export const FULL_DATE_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const MAP_API_KEY = "AIzaSyAl1vuEnve1mrv2B6GGx-qQJ-SOTHlHOdU";

export const TIMEOUT_MSECS = 5000;

export const POLLING_MSECS = 20000;

export const SUCCESS_MESSAGES = {
  verifiedNumber: "Phone number verified successfully",
  getIssues: "Update successful",
  addIssue: "Issue added successfully",
  updateIssue: "Issue updated successfully",
  deleteIssue: "Issue archived successfully",
  undoDeleteIssue: "Issue un-archived successfully",
  getAllUsers: "User list updated successfully",
  getRiderUsers: "Updated successfully",
  getAdminUsers: "Updated successfully",
  getMechanicUsers: "Updated successfully",
  deleteUser: "User deleted successfully",
  blockAccount: "Accound blocked successfully",
  addUser: "User added successfully",
  updateUser: "User updated successfully",
  getVehicles: "Updated successfully",
  addVehicle: "Vehicle added successfully",
  uploadCsv: "Upload completed successfully",
  deleteVehicle: "Vehicle deleted successfully",
  updateVehicle: "Vehicle updated successfully",
  firmwareVehicle: "Vehicle Firmware updated successfully",
  addFirmwareToSpaces: "Vehicle firmware uploaded successfully",
  addVehicleModel: "Model added successfully...",
  updateVehicleModel: "Model updated successfully...",
  modelFirmwareUpload: "Firmware uploaded successfully...",
  modelUpdateFirmware: "Firmware upgrade initiated successfully...",
  deleteVehicleModel: "Model deleted successfully...",
  addBattery: "Battery added successfully...",
  updateBattery: "Battery Updated successfully...",
  updateBatteryModel: "Battery model updated successfully...",
  addBatteryModel: "Battery model added successfully...",
  deleteBatteryModel: "Battery model archived successfully...",
  deleteBattery: "Battery archived successfully...",
  addOrganization: "Organization is added successfully...",
  updateOrganization: "Organization updated successfully...",
  addZone: "Zone added successfully...",
  editZone: "Zone updated successfully...",
  mqtt_server_change: "Server changed successfully...",
};

export const IS_DASHBOARD_SHOW = true;

export const ERROR_MESSAGES = {
  verifyCode: "Verification code incorrect",
};

export const DASHBOARD_OPTIONS: {
  value: DashboardState["selectedDashboard"];
  label: string;
}[] = [
  { value: "issues", label: "Issues & Tasks" },
  { value: "vehicles", label: "Vehicles" },
  { value: "financials", label: "Financials" },
];

export const ALL_WIDGETS: {
  [key in DashboardState["selectedDashboard"]]: ChartRepoItem[];
} = {
  issues: ISSUES_CHARTS_META,
  vehicles: VEHICLES_CHARTS_META,
  financials: FINANCIALS_CHARTS_META,
};

export const DEFAULT_AVATER =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKpeDKUVlK_AzUItjz2AGCxLCRqIsSk-2iLw&usqp=CAU";
