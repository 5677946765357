import { format } from "date-fns";

import { BatteryState } from "types/store";

export function parseBatteryTable(
    search: BatteryState["search"],
    list?: BatteryState["batteries"],
    listType?: BatteryState["filters"]["listType"]
) {
    if (list) {
        const data = list.map((battery, index) => ({
            key: index,
            battery_id: battery.batteryId,
            battery_alias: battery.batteryAlias,
            soc_level: parseFloat(battery.socLevel != ""? battery.socLevel:"0").toString() +"%" || "-",
          //  soh_level:  "-",
            battery_health: battery.batteryHealth,
            operational_state: battery.operationalState || "-",
            make_model: battery.make_model,
            battery_: battery.batteryAlias,
            host_id: battery.hostId != "00000000-0000-0000-0000-000000000000" ? battery.hostId || "-" : "-",
            action: "",
            raw: battery,
            listType: listType,
        }));
        return data
    }

    return [];
}

export function parseBatteryDetailTable(
    list?: BatteryState["batteryDetail"]
){
    console.log("list in battery detail parse", list);
    return [];
}

export function parseBatteryHeartbeatTable(
    list?: BatteryState["battery_heartbeat"]
){
    console.log("list in battery heartbeat parse", list);

    return [];
}



